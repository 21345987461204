<template>
  <div class="square">
    <div class="header cross-center fs28 fc999 dfr">
      <div
        @click="allSquare"
        class="all h100 dfr flex-center header-item"
        :class="[active == 1 ? 'active fs32 fc333' : 'fs28 fc999']"
      >
        全部
      </div>
      <div
        @click="recommended"
        class="h100  dfr flex-center header-item"
        :class="[active == 2 ? 'active fs32 fc333' : 'fs28 fc999']"
      >
        我推荐的
      </div>
      <div :class="[active == 1 ? 'first' : 'second']" class="bottom-line fs0">
        <img class="w100 h100" src="../assets/images/square.png" alt="" />
      </div>
    </div>

    <div class="feeling-wrap dfc flex-center" v-if="activeShowOne" active="1">
      <van-list
        :offset="200"
        v-model="allLoading"
        loading-text=""
        :finished="allFinished"
        v-if="feelingList.length"
        @load="allOnLoad"
      >
        <div
          v-for="(item, index) in feelingList"
          :key="index"
          class="feeling-item"
        >
          <squareFeeling-card :info="item"></squareFeeling-card>
        </div>
      </van-list>
      <empty v-else></empty>
    </div>

    <div class="feeling-wrap dfc flex-center" v-if="activeShowTwo" active="2">
      <van-list
        :offset="200"
        v-model="recommendLoading"
        loading-text=""
        :finished="recommendFinished"
        v-if="recommendList.length"
        @load="recommendOnLoad"
      >
        <div
          v-for="(item, index) in recommendList"
          :key="index"
          class="feeling-item"
        >
          <squareFeeling-card :info="item"></squareFeeling-card>
        </div>
      </van-list>
      <empty v-else></empty>
    </div>
    <tab-bar active="1"></tab-bar>
  </div>
</template>

<script>
import { timestampFormat } from "../utils/util";
import dayjs from "dayjs";
export default {
  data() {
    return {
      active: 1,
      activeShowOne: true,
      activeShowTwo: false,
      gettime: "",
      // 全部
      feelingList: [],
      // 推荐
      recommendList: [],
      allPage: 1,
      allPageSize: 10,
      allCurrentTotal: "",
      allFinished: false,
      allLoading: false,
      recommendPage: 1,
      recommendPageSize: 10,
      recommendCurrentTotal: "",
      recommendFinished: false,
      recommendLoading: false
    };
  },
  created() {
    this.$title("感想广场");
    this.initPage();
  },
  activated() {
    this.$title("感想广场");
    let scrollTop = this.$localStore.get("squareScrollTop");
    window.scrollTo(0, scrollTop);
  },
  beforeRouteLeave(to, from, next) {
    const getScrollTop = () => {
      var scrollTop =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      return scrollTop;
    };
    let scrollTop = getScrollTop();
    this.$localStore.set("squareScrollTop", scrollTop);
    next();
  },

  mounted() {},
  methods: {
    async initPage() {
      this.initAll();
    },
    async initAll() {
      this.allPage = 1;
      this.feelingList = await this.initAllList();
      if (this.allCurrentTotal < this.allPageSize) {
        this.allLoading = false;
        this.allFinished = true;
      } else {
        this.allLoading = false;
        this.allFinished = false;
      }
      this.allLoading = false;
    },
    async initRecommend() {
      this.recommendPage = 1;
      this.recommendList = await this.initRecommendList();
      if (this.recommendCurrentTotal < this.recommendPageSize) {
        this.recommendLoading = false;
        this.recommendFinished = true;
      } else {
        this.recommendLoading = false;
        this.recommendFinished = false;
      }
      this.recommendLoading = false;
    },
    // 获取列表
    async initAllList() {
      let list = [];
      const data = {
        page: this.allPage,
        pageSize: this.allPageSize
      };
      const resp = await this.$API
        .get("squareList", data, false)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.allCurrentTotal = resp.data.length;
        list = resp.data.list;
        list.forEach(item => {
          item.created_at = timestampFormat(
            dayjs(item.created_at).valueOf() / 1000
          );
        });
      }
      return list;
    },
    async initRecommendList() {
      let list = [];
      const data = {
        page: this.recommendPage,
        pageSize: this.recommendPageSize
      };
      const resp = await this.$API
        .get("squareRecommon", data, true)
        .then(resp => resp);
      if (resp.code == 1000) {
        this.recommendCurrentTotal = resp.data.length;
        list = resp.data.list;
        list.forEach(item => {
          item.created_at = timestampFormat(
            dayjs(item.created_at).valueOf() / 1000
          );
        });
      }
      return list;
    },
    // 触发加载
    async allOnLoad() {
      if (!this.allFinished) {
        this.allPage++;
        const allList = await this.initAllList();
        if (allList.length < this.allPageSize) {
          this.feelingList = [...this.feelingList, ...allList];
          this.allLoading = false;
          this.allFinished = true;
        } else {
          this.feelingList = [...this.feelingList, ...allList];
          this.allLoading = false;
          this.allFinished = false;
        }
      }
    },
    async recommendOnLoad() {
      if (!this.recommendFinished) {
        this.recommendPage++;
        const recommendList = await this.initRecommendList();

        if (recommendList.length < this.recommendPageSize) {
          this.recommendList = [...this.recommendList, ...recommendList];
          this.recommendLoading = false;

          this.recommendFinished = true;
        } else {
          this.recommendList = [...this.recommendList, ...recommendList];
          this.recommendLoading = false;
          this.recommendFinished = false;
        }
      }
    },
    // 点击全部
    allSquare() {
      window.scrollTo(0, 0);
      this.allPage = 1;
      this.active = 1;
      this.activeShowTwo = false;
      this.activeShowOne = true;
      this.initAll();
    },
    // 点击推荐
    recommended() {
      window.scrollTo(0, 0);
      this.recommendPage = 1;
      this.active = 2;
      this.activeShowOne = false;
      this.activeShowTwo = true;
      let isLogin = this.$localStore.get("isLogin");
      if (!isLogin) {
        this.$toast("您还未登录，请先登录！");
        this.$go("login");
        return;
      } else {
        this.initRecommend();
      }
    }
  },
  destroyed() {}
};
</script>
<style lang="less" scoped>
.square {
  .header {
    position: sticky;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    height: 0.88rem;
    background: #fff;
    padding-left: 0.28rem;
    .all {
      margin-right: 0.5rem;
    }
    .bottom-line {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0.44rem;
      height: 0.12rem;
      transform: all 1s;
      &.first {
        transform: translateX(0.34rem);
      }
      &.second {
        transform: translateX(1.74rem);
      }
    }
  }
  .feeling-wrap {
    margin-top: 0.9rem;
    margin-bottom: 0.4rem;
  }
}
</style>
